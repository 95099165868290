import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import ChallengeLayout from '../layouts/challenge-layout';

export const query = graphql`
  query($pathSlug: String!) {
    mdx(slug: { eq: $pathSlug }) {
      slug
      frontmatter {
        title
        createDate(formatString: "DD MMMM YYYY")
        updateDate(formatString: "DD MMMM YYYY")
        path
      }
      body
      tableOfContents
    }
  }
`;

const Template = ({ data: { mdx } }) => {
  // console.log('CHALLENGE TEMPLATE mdx------', mdx);
  const { body } = mdx;

  // console.log('slug', slug);

  return (
    <>
      <br />
      <ChallengeLayout>
        <MDXRenderer>{body}</MDXRenderer>
      </ChallengeLayout>
    </>
  );
};

export default Template;

import React from 'react';
import styles from './challenge-layout.module.scss';

const ChallengeLayout = ({ children }) => {

  return (
    <div className={styles.challengeLayout}>
      <div className="blog container mx-auto my-2 h-auto">
        <div className={`grid grid-cols-1 gap-4`}>
          <main className="mx-2">
            {children}
          </main>
        </div>
      </div>
    </div>
  );
};

export default ChallengeLayout;
